export default [
    {
        name: 'Action',
        url: require('@/assets/classicon/Action.png'),
        surl: require('@/assets/classicon/Action1.png'),
    },{
        name: 'Adventure',
        url: require('@/assets/classicon/Adventure.png'),
        surl: require('@/assets/classicon/Adventure1.png'),
    },{
        name: 'Board',
        url: require('@/assets/classicon/Board.png'),
        surl: require('@/assets/classicon/Board1.png'),
    },{
        name: 'Boys',
        url: require('@/assets/classicon/Boys.png'),
        surl: require('@/assets/classicon/Boys1.png'),
    },{
        name: 'Casual',
        url: require('@/assets/classicon/Casual.png'),
        surl: require('@/assets/classicon/Casual1.png'),
    },{
        name: 'Eliminate',
        url: require('@/assets/classicon/Eliminate.png'),
        surl: require('@/assets/classicon/Eliminate1.png'),
    },{
        name: 'Fighting',
        url: require('@/assets/classicon/Fighting.png'),
        surl: require('@/assets/classicon/Fighting1.png'),
    },{
        name: 'Girls',
        url: require('@/assets/classicon/Girls.png'),
        surl: require('@/assets/classicon/Girls1.png'),
    },{
        name: 'Hot',
        url: require('@/assets/classicon/Hot.png'),
        surl: require('@/assets/classicon/Hot1.png'),
    },{
        name: 'Ldie',
        url: require('@/assets/classicon/Ldie.png'),
        surl: require('@/assets/classicon/Ldie1.png'),
    },{
        name: 'Mini',
        url: require('@/assets/classicon/Mini.png'),
        surl: require('@/assets/classicon/Mini1.png'),
    },{
        name: 'New',
        url: require('@/assets/classicon/New.png'),
        surl: require('@/assets/classicon/New1.png'),
    },{
        name: 'Popular',
        url: require('@/assets/classicon/Popular.png'),
        surl: require('@/assets/classicon/Popular1.png'),
    },{
        name: 'Puzzle',
        url: require('@/assets/classicon/Puzzle.png'),
        surl: require('@/assets/classicon/Puzzle1.png'),
    },{
        name: 'Racing',
        url: require('@/assets/classicon/Racing.png'),
        surl: require('@/assets/classicon/Racing1.png'),
    },{
        name: 'RacingGame',
        url: require('@/assets/classicon/RacingGame.png'),
        surl: require('@/assets/classicon/RacingGame1.png'),
    },{
        name: 'Simulated',
        url: require('@/assets/classicon/Simulated.png'),
        surl: require('@/assets/classicon/Simulated1.png'),
    },{
        name: 'Sports',
        url: require('@/assets/classicon/Sports.png'),
        surl: require('@/assets/classicon/Sports1.png'),
    },{
        name: 'Tower',
        url: require('@/assets/classicon/Tower.png'),
        surl: require('@/assets/classicon/Tower1.png'),
    },{
        name: 'Place',
        url: require('@/assets/classicon/Place.png'),
        surl: require('@/assets/classicon/Place1.png'),
    },{
        name: 'Classic',
        url: require('@/assets/classicon/Classic.png'),
        surl: require('@/assets/classicon/Classic1.png'),
    },{
        name: 'Parkour',
        url: require('@/assets/classicon/Parkour.png'),
        surl: require('@/assets/classicon/Parkour1.png'),
    },

]