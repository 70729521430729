<template>
    <div style="padding: 0 20px;box-sizing: border-box">
        <p class="home-text">
            Search
        </p>
        <div class="search-cont noselect">
            <p class="noselect">GAMES</p>
            <div>
                <img src="@/assets/slices/search.png" alt="">
                <input type="text" v-model="searchText" @keydown.enter="searchGame">
            </div>
        </div>
        <div class="class-cont">
            <p class="noselect">Sort</p>
            <div class="class-info">
                <div
                    class="pointer"
                    v-for="item in classList"
                    :key="item.name"
                    @click="selectClass(item.name)"
                >
                    <img :src="item.name===sClass?item.surl:item.url" alt="">
                </div>
            </div>
        </div>
        <div class="game-cont">
            <div class="game-head">
                <div class="head-l noselect">{{ title }}</div>
            </div>
            <div class="game-list">
                <div
                    class="game-card pointer"
                    v-for="item in gameList"
                    :key="item.id"
                    @click="goDetails(item)"
                >
                    <img :src="item.logo" alt="">
                    <div class="no-warp title">{{item.title}}</div>
                    <div class="category">{{item.key_name}}</div>
                    <div class="rating">
                        <div>
                            {{item.rating}}
                        </div>
                        <img v-for="i in 5" :key="i" :src="item.rating<i?star:stars" alt="">
                    </div>
                </div>
                <span v-for="i in 5" :key="i+'s'"></span>
            </div>
        </div>
        <div class="game-cont" v-if="showMore">
            <div class="game-head">
                <div class="head-l noselect">more fun game</div>
            </div>
            <div class="game-list">
                <div
                    class="game-card pointer"
                    v-for="item in moreList"
                    :key="item.id"
                    @click="goDetails(item)"
                >
                    <img :src="item.logo" alt="">
                    <div class="no-warp title">{{item.title}}</div>
                    <div class="category">{{item.key_name}}</div>
                    <div class="rating">
                        <div>
                            {{item.rating}}
                        </div>
                        <img v-for="i in 5" :key="i" :src="item.rating<i?star:stars" alt="">
                    </div>
                </div>
                <span v-for="i in 5" :key="i+'s'"></span>
            </div>
        </div>
        <div class="class-info" v-if="!showMore">
            {{ intro }}
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus';
import classIcon from "@/utils/classList";
import { isMobile } from '@/utils/api';
import { getGameByKey, searchGame, getClassList, getGame  } from "@/api/games";
import star from "@/assets/slices/star.png";
import stars from "@/assets/slices/stars.png";
import classInfo from "@/utils/classInfo";

    export default {
        data() {
            return {
                title: '',
                gameList: [],
                moreList: [],
                spanNum:0,
                classList: [],
                sClass: '',
                intro: '',
                searchText: '',
                showMore: false,
                star,
                stars
            }
        },
        mounted() {
            if(isMobile()){
                this.spanNum = 3-2;
            }else{
                this.spanNum = 11-2;
            }
            this.getClass();
            eventBus.$on('searchgame',name=>{
                let {id,key} = name;
                this.getGameList(id,key)
            })
            let {id,key} = this.$route.query
            this.getGameList(id,key);
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            searchGame(){
                this.getGameList('search',this.searchText);
            },
            selectClass(key){
                this.getGameList('more',key);
            },
            async getClass(){
                let list = await getClassList();
                list.forEach((item,i) => {
                    let data = classIcon.find(items => item.key_name === items.name);
                    if(data){
                        list.splice(i,1,data)
                    }
                })
                this.classList = list;
            },
            async getGameList(id,key){
                let games = [];
                let title = '';
                if(id=='search'){
                    games = await searchGame({q:key});
                    title = `search found "${key}"`;
                    this.moreList = await getGame({count:20});
                    this.showMore = true;
                }else if(id=='more'){
                    games = await getGameByKey({category:key});
                    this.intro = classInfo.find(item => item.name === key).intro;
                    this.sClass = key;
                    title = key + ' games';
                    this.showMore = false;
                }else{
                    console.log(key);
                }
                this.gameList = games;
                this.title = title;
            }
        },
    }
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.home-text{
    height: 37px;
    margin-bottom: 57px;
    font-family: Playfair Display, Playfair Display;
    font-weight: bold;
    font-size: 28px;
    color: #FFFFFF;
    line-height: 33px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}
@media (min-width: 768px) {
    .search-cont{
        width: 335px;
    }
}
.search-cont{
    margin-bottom: 40px;
    p{
        width: 70px;
        height: 15px;
        margin-bottom: 5px;
        font-family: STIX Two Text, STIX Two Text;
        font-weight: 500;
        font-size: 12px;
        color: rgba(255,255,255,0.82);
        line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    div{
        height: 40px;
        background: #312F2F;
        border-radius: 13px;
        display: flex;
        align-items: center;
        img{
            width: 15px;
            height: 15px;
            margin: 0 15px;
        }
        input{
            height: 75%;
            border: none;
            outline: none;
            background: #312F2F;
            color: #FFF;
        }
    }
}
.class-cont{
    margin-bottom: 30px;
    p{
        width: 32px;
        height: 20px;
        margin-bottom: 19px;
        font-family: STIX Two Text, STIX Two Text;
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .class-info{
        overflow: auto;
        display: flex;
        @media (min-width: 768px){
            justify-content: space-between;
        }
        img{
            width: 55px;
            height: 72px;
            margin-right: 10px;
        }
    }
}
.game-cont{
    color: #FFF;
    margin-bottom: 55px;
    .game-head{
        margin-bottom: 20px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .game-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .game-card{
            width: 162px;
            margin-bottom: 25px;
            img{
                width: 162px;
                height: 162px;
                border-radius: 15px;
            }
            .title{
                margin: 11px 0 2px;
                height: 19px;
                font-family: STIX Two Text, STIX Two Text;
                font-weight: bold;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 19px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
            .category{
                margin-bottom: 9px;
                height: 15px;
                font-family: STIX Two Text, STIX Two Text;
                font-weight: 400;
                font-size: 12px;
                color: #FFFFFF;
                line-height: 14px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
            .rating{
                display: flex;
                align-items: center;
                div{
                    width: 31px;
                    height: 18px;
                    background: #FF5656;
                    border-radius: 7px;
                    text-align: center;
                    font-family: Montserrat, Montserrat;
                    font-weight: 400;
                    font-size: 11px;
                    color: #FFFFFF;
                    line-height: 18px;
                    font-style: normal;
                    text-transform: none;
                    margin-right: 5px;
                }
                img{
                    width: 13px;
                    height: 13px;
                }
            }
        }
        span{
            width: 162px;
        }
    }
}
.class-info{
    padding: 8px 12px;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #FFF;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>