<template>
    <div style="padding: 0 20px;box-sizing: border-box">
        <adContent></adContent>
        <p class="home-text noselect">
            Search
        </p>
        <div class="search-cont">
            <p class="noselect">GAMES</p>
            <div>
                <img src="@/assets/slices/search.png" alt="">
                <input type="text" v-model="searchText" @keydown.enter="searchGame">
            </div>
        </div>
        <div class="class-cont">
            <p class="noselect">Sort</p>
            <div class="class-info">
                <div
                    class="pointer"
                    v-for="item in classList"
                    :key="item.name"
                    @click="selectClass(item.name)"
                >
                    <img :src="item.name===sClass?item.surl:item.url" alt="">
                </div>
            </div>
        </div>
        <div class="game-cont"
             v-for="(games,key) in gameList"
             :key="key"
        >
            <div class="game-head">
                <div class="head-l noselect">{{key}}</div>
                <div class="head-r pointer" @click="selectClass(games[0].key_name)">MORE</div>
            </div>
            <div class="game-list">
                <div
                    class="game-card pointer"
                    v-for="item in games"
                    :key="item.id"
                    @click="goDetails(item)"
                >
                    <img :src="item.logo" alt="">
                    <div class="no-warp title">{{item.title}}</div>
                    <div class="category">{{item.key_name}}</div>
                    <div class="rating">
                        <div>
                            {{item.rating}}
                        </div>
                        <img v-for="i in 5" :key="i" :src="item.rating<i?star:stars" alt="">
                    </div>
                </div>
                <span v-for="i in 5" :key="i+'s'"></span>
            </div>
        </div>
        <div class="game-blurb noselect">
            Welcome to {{blurbUrl}} Here, various free online games can be played directly in your browser without any downloads. Our collection of online games will provide you with hours ofentertainment. Invite your friends over and enjoy these popular games together. From classic arcade games to car games, cricket games, puzzles, mahjong, and more, there's something for everyone in our game categories. We have carefully selected the best online games, and we review and add new games every day, so you can learn new skills as you play! Let's start playing
        </div>
    </div>
</template>

<script>
import {
    getClassList,
    getGameList,
} from "@/api/games";
import adContent from "@/components/adContent/index.vue";
import classIcon from "@/utils/classList";
import star from "@/assets/slices/star.png";
import stars from "@/assets/slices/stars.png";

    export default {
        components: {
            adContent
        },
        data() {
            return {
                gameList: [],
                searchText: '',
                classList: [],
                sClass: '',
                intro: '',
                star,
                stars
            }
        },
        mounted() {
            this.getList();
            this.getClass();
        },
        methods: {
            goDetails(item){
                this.$router.push({
                    path:'/details',
                    query:{
                        id:item.id
                    }
                })
            },
            searchGame(){
                this.$router.push({
                    path: '/selectGame',
                    query: {
                        id: 'search',
                        key: this.searchText
                    }
                })
            },
            async getList(){
                this.gameList = await getGameList();
            },
            async getClass(){
                let list = await getClassList();
                list.forEach((item,i) => {
                    let data = classIcon.find(items => item.key_name === items.name);
                    if(data){
                        list.splice(i,1,data)
                    }
                })
                this.classList = list;
            },
            async selectClass(name){
                if(this.sClass === name) return;
                this.sClass = name;
                this.$router.push({
                    path: '/selectGame',
                    query: {
                        id: 'more',
                        key: name
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.home-text{
    height: 37px;
    margin-bottom: 57px;
    font-family: Playfair Display, Playfair Display;
    font-weight: bold;
    font-size: 28px;
    color: #FFFFFF;
    line-height: 33px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}
@media (min-width: 768px) {
    .search-cont{
        width: 335px;
    }
}
.search-cont{
    margin-bottom: 40px;
    p{
        width: 70px;
        height: 15px;
        margin-bottom: 5px;
        font-family: STIX Two Text, STIX Two Text;
        font-weight: 500;
        font-size: 12px;
        color: rgba(255,255,255,0.82);
        line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    div{
        height: 40px;
        background: #312F2F;
        border-radius: 13px;
        display: flex;
        align-items: center;
        img{
            width: 15px;
            height: 15px;
            margin: 0 15px;
        }
        input{
            height: 75%;
            border: none;
            outline: none;
            background: #312F2F;
            color: #FFF;
        }
    }
}
.class-cont{
    margin-bottom: 30px;
    p{
        width: 32px;
        height: 20px;
        margin-bottom: 19px;
        font-family: STIX Two Text, STIX Two Text;
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .class-info{
        overflow: auto;
        display: flex;
        @media (min-width: 768px){
            justify-content: space-between;
        }
        img{
            width: 55px;
            height: 72px;
            margin-right: 10px;
        }
    }
}
.game-cont{
    color: #FFF;
    .game-head{
        display: flex;
        justify-content: space-between;
        margin-bottom: 19px;
        .head-l{
            height: 20px;
            font-family: STIX Two Text, STIX Two Text;
            font-weight: bold;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
        .head-r{
            height: 15px;
            font-family: STIX Two Text, STIX Two Text;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 14px;
            text-align: right;
            font-style: normal;
            text-transform: none;
        }
    }
    .game-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .game-card{
            width: 162px;
            margin-bottom: 25px;
            img{
                width: 162px;
                height: 162px;
                border-radius: 15px;
            }
            .title{
                margin: 11px 0 2px;
                height: 19px;
                font-family: STIX Two Text, STIX Two Text;
                font-weight: bold;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 19px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
            .category{
                margin-bottom: 9px;
                height: 15px;
                font-family: STIX Two Text, STIX Two Text;
                font-weight: 400;
                font-size: 12px;
                color: #FFFFFF;
                line-height: 14px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
            .rating{
                display: flex;
                align-items: center;
                div{
                    width: 31px;
                    height: 18px;
                    background: #FF5656;
                    border-radius: 7px;
                    text-align: center;
                    font-family: Montserrat, Montserrat;
                    font-weight: 400;
                    font-size: 11px;
                    color: #FFFFFF;
                    line-height: 18px;
                    font-style: normal;
                    text-transform: none;
                    margin-right: 5px;
                }
                img{
                    width: 13px;
                    height: 13px;
                }
            }
        }
        span{
            width: 162px;
        }
    }
}
.game-blurb{
    //background: #FFFFFF;
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #FFF;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>