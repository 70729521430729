<template>
    <div>
        <div class="header">
            <img class="logo pointer" src="@/assets/slices/logo.png" alt="" @click="goIndex">
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showMenu: false,
                searchText: ''
            }
        },
        mounted() {

        },
        methods: {
            goIndex(){
                if(this.$route.path == '/'){
                    this.$router.go(0)
                }else{
                    this.$router.push('/')
                }
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .header{
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .header{
    width: 100%;
  }
}
.pointer{
    cursor: pointer;
}
.header{
    height: 102px;
    background: #1C1C1C;
    position: fixed;
    top: 0;
    z-index: 99999;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .logo{
        width: 125px;
        height: 31px;
    }
}
</style>