<template>
    <div style="padding: 0 15px;box-sizing: border-box">
        <div class="top-det" v-if="isPlay">
            <img :src="gameInfo.logo" alt="">
            <div class="top-title no-warp">{{gameInfo.title}}</div>
            <div class="top-rating">
                <div>{{gameInfo.rating}}</div>
                <img v-for="i in 5" :key="i" :src="gameInfo.rating<i?star:stars" alt="">
            </div>
            <div class="top-play pointer" @click="()=>isPlay = false">PLAY</div>
        </div>
        <div class="gamewin" v-else>
            <iframe
                style="width:100%"
                :src="baseUrl+gameInfo.url"
                frameborder="0"></iframe>
        </div>
        <div class="game-syn noselect">
            <div class="syn-h">Description:</div>
            <div class="syn-c warp-6">
                {{gameInfo.synopsis}}
            </div>
        </div>
        <div class="game-cont">
            <div class="game-head">
                <div class="head-l noselect">More Games</div>
            </div>
            <div class="game-list">
                <div
                    class="game-card pointer"
                    v-for="item in gameList"
                    :key="item.id"
                    @click="goDetails(item)"
                >
                    <img :src="item.logo" alt="">
                    <div class="no-warp title">{{item.title}}</div>
                    <div class="category">{{item.key_name}}</div>
                    <div class="rating">
                        <div>
                            {{item.rating}}
                        </div>
                        <img v-for="i in 5" :key="i" :src="item.rating<i?star:stars" alt="">
                    </div>
                </div>
                <span v-for="i in 5" :key="i+'s'"></span>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile, scrollToTop } from "@/utils/api"
import { getGameById } from "@/api/games"
import star from "@/assets/slices/star.png";
import stars from "@/assets/slices/stars.png";
    export default {
        data() {
            return {
                value:4,
                gameList:[],
                gameInfo:{},
                spanNum:0,
                isPlay:true,
                baseUrl:'',
                star,
                stars
            }
        },
        mounted() {
            let id = this.$route.query.id

            this.getGameInfo(id);

            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 11-2
            }

            // this.baseUrl = process.env.VUE_APP_BASE_GAME
            this.baseUrl = 'https://'+window.location.hostname

            scrollToTop(0);
        },
        methods: {
            async getGameInfo(id){
                let { game,similarGames } = await getGameById({id})
                this.gameInfo = game;
                this.gameList = similarGames;
            },

            goDetails(item){
                this.gameInfo = item;
                this.isPlay = true;
                scrollToTop(0);
            }
        },
    }
</script>

<style lang="less" scoped>
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.warp-6{
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.top-det{
    color: #FFF;
    >img{
        display: block;
        width: 270px;
        height: 270px;
        border-radius: 20px;
        margin: 0 auto;
    }
    .top-title{
        margin: 19px auto 14px;
        width: 229px;
        height: 21px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 19px;
        text-align: center;
        font-style: normal;
        text-transform: none;
    }
    .top-rating{
        display: flex;
        align-items: center;
        justify-content: center;
        div{
            width: 42px;
            height: 24px;
            background: #FF5656;
            border-radius: 10px;
            text-align: center;
            font-family: Montserrat, Montserrat;
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 24px;
            font-style: normal;
            text-transform: none;
            margin-right: 7px;
        }
        img{
            width: 16px;
            height: 16px;
        }
    }
    .top-play{
        margin: 22px auto 42px;
        width: 300px;
        height: 40px;
        background: #FF5656;
        border-radius: 10px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        font-style: normal;
        text-transform: none;
    }
}
.gamewin{
    width: 100%;
    height: calc(100vh - 102px);
    margin-bottom: 16px;
    iframe{
        width: 100%;
        height: 100%;
    }
}
.game-syn{
    margin-bottom: 80px;
    .syn-h{
        margin-bottom: 11px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 18px;
        color: #FF5656;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .syn-c{
        font-family: Inter, Inter;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
}
.game-cont{
    color: #FFF;
    margin-bottom: 55px;
    .game-head{
        margin-bottom: 17px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 18px;
        color: #FF5656;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }
    .game-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .game-card{
            width: 162px;
            margin-bottom: 25px;
            img{
                width: 162px;
                height: 162px;
                border-radius: 15px;
            }
            .title{
                margin: 11px 0 2px;
                height: 19px;
                font-family: STIX Two Text, STIX Two Text;
                font-weight: bold;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 19px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
            .category{
                margin-bottom: 9px;
                height: 15px;
                font-family: STIX Two Text, STIX Two Text;
                font-weight: 400;
                font-size: 12px;
                color: #FFFFFF;
                line-height: 14px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
            .rating{
                display: flex;
                align-items: center;
                div{
                    width: 31px;
                    height: 18px;
                    background: #FF5656;
                    border-radius: 7px;
                    text-align: center;
                    font-family: Montserrat, Montserrat;
                    font-weight: 400;
                    font-size: 11px;
                    color: #FFFFFF;
                    line-height: 18px;
                    font-style: normal;
                    text-transform: none;
                    margin-right: 5px;
                }
                img{
                    width: 13px;
                    height: 13px;
                }
            }
        }
        span{
            width: 162px;
        }
    }
}
</style>